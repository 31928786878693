/* 전체 컨테이너 스타일 */
.container {
  max-width: 600px;
  margin: 20px auto;
  margin-left: 106px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
}
.App {
  text-align: center;
  margin-left: auto;
}

/* 제목 스타일 */
h2 {
  text-align: center;
  color: #333;
}

/* 입력 필드와 레이블 스타일 */
label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #555;
}

input[type="text"],
input[type="number"] {
  width: auto;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

input[type="checkbox"] {
  margin-top: 10px;
}



#buildingContainer {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  margin-left: 106px;
  margin-top: 30px;
}

.building {
  margin: 20px;
  padding: 10px;
  border: 1px solid #ccc;
}

/* Style for each floor */
.floor {
  display: flex; /* Use flexbox to align rooms side by side */
  justify-content: flex-end; /* Center rooms on the floor */
  margin-bottom: 10px;
  
}

/* Individual room styles */
.room {
  border: 1px solid #000;
  justify-content: space-around; /* 방을 균등하게 배치 */
  background: #dbdbdb;
  color: rgb(49, 49, 49);
  padding: 10px;
  margin: 5px;
  flex: 1; /* 방 크기 조정 */
  text-align: center; /* 텍스트 중앙 정렬 */
}




.all-settings-container {
  padding: 20px;
  margin-left: 10px;
}
.line-container {
  display: grid;
  align-items: baseline;
}
.room-grid {
  display: flex;
  flex-wrap: wrap; /* 화면 크기에 맞춰 아이템들이 줄 바꿈되도록 설정 */
  gap: 10px; /* 아이템들 사이에 간격 추가 */
  margin-top: 10px;
  justify-content: flex-start; /* 시작 부분에서부터 아이템을 나열 */
}

.room-item {
  
  display: flex;
  align-items: center; /* label과 input이 같은 높이로 정렬되도록 설정 */
  justify-content: space-between;
  width: 40px; /* 각 아이템의 최소 너비 설정 */

}

label {
  display: flex; /* Flexbox로 설정하여 중앙 정렬을 사용 */
  justify-content: center; /* 수평 중앙 정렬 */
  align-items: center; /* 수직 중앙 정렬 */
  margin-right: 0px; /* 입력란과 레이블 사이에 여백 추가 */
  min-width: 80px; /* 일정한 너비를 확보하여 레이블과 입력 필드의 정렬이 깨지지 않도록 설정 */
}


input[type="text"] {
  width: 100px; 
  padding: 8px ;
  margin-left: 6px;
  box-sizing: border-box;
}











.centered-container {
  text-align: center;
}

.custom-divider {
  border-bottom: 2px solid #000;
  margin: 20px auto;  /* Add margin to center it */
  width: 80%;         /* Set width to 80% for the divider */
}
