.App {
  text-align: center;
  margin-left: 106px;
}


.container {
  display: flex;
  margin-left: 106px;
}

.main-content {
  margin-left: 106px; /* 사이드바 너비에 맞게 여백 */
  padding: 20px; /* 콘텐츠 패딩 */
  flex-grow: 1; /* 나머지 공간을 채우도록 설정 */
}

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
