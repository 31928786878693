@import url('https://getbootstrap.com/dist/css/bootstrap.css');
@import url("//netdna.bootstrapcdn.com/bootstrap/3.0.0-rc2/css/bootstrap-glyphicons.css");

body {
  background: white;
}

nav.sidebar-menu-collapsed {
  width: 50px;
}

nav.sidebar-menu-expanded {
  width: 106px;
}

nav.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  background: #e78420;
  color: white;
  padding: 20px 10px;
}

nav.sidebar a#justify-icon {
  outline: 0;
  color: white;
  font-size: 24px;
  font-style: normal;
}

nav.sidebar a#logout-icon {
  outline: 0;
  color: white;
  font-size: 24px;
  font-style: normal;
  position: absolute;
  bottom: 10px;
  left: 10px;
}

nav.sidebar ul {
  margin: 0;
  padding: 0;
  margin-top: 60px;
}

nav.sidebar ul li {
  margin: 0;
  padding: 0;
  margin-top: 20px;
  list-style-type: none;
}

nav.sidebar ul li a.expandable {
  outline: 0;
  color: white;
  text-decoration: none;
  font-size: 20px;
}

nav.sidebar ul li a.expandable:hover {
  color: #bbbbbb;
}

nav.sidebar ul li a.expandable span.expanded-element {
  margin-left: 2px;
  display: none;
  font-size: 11px;
  position: relative;
  bottom: 2px;
}

nav.sidebar ul li.active {
  background: black;
  border-radius: 4px;
  text-align: center;
  margin-left: -4px;
  padding: 4px;
}

nav.sidebar ul li.active a.expandable {
  color: white !important;
}

nav.sidebar ul li.active a.expandable:hover {
  color: white !important;
}
nav.sidebar ul li a.expandable span.expanded-element {
  margin-left: 2px;
  display: none; /* 기본적으로 숨김 */
  font-size: 11px;
  position: relative;
  bottom: 2px;
}

/* show 클래스가 있을 때만 표시되도록 설정 */
nav.sidebar ul li a.expandable span.expanded-element.show {
  display: inline; /* 인라인으로 표시 */
}
