/* AddBuildingForm.css */

/* 전체 컨테이너 스타일 */
.add-building-form {
    max-width: 600px; /* 최대 너비 설정 */
    margin: 20px auto; /* 상하 여백과 중앙 정렬 */
    padding: 20px; /* 내쪽 여백 */
    background-color: #f9f9f9; /* 배경색 */
    border-radius: 8px; /* 둥근 모서리 */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* 그림자 효과 */
  }
  
  /* 제목 스타일 */
  .add-building-form h2 {
    text-align: center; /* 가운데 정렬 */
    color: #333; /* 텍스트 색상 */
  }
  
  /* 입력 필드 스타일 */
  .line-input {
    margin-bottom: 20px; /* 각 라인 사이 여백 */
    padding: 10px; /* 내쪽 여백 */
    border: 1px solid #ccc; /* 테두리 */
    border-radius: 4px; /* 둥근 모서리 */
    background-color: #fff; /* 배경색 */
  }
  
  /* 라벨 스타일 */
  .line-input label {
    display: block; /* 블록 요소로 설정 */
    margin-top: 10px; /* 위쪽 여백 */
  }
  



  .bd-input {

    width: 200px; /* 너비를 200px로 설정 */

  }
  /* 버튼 스타일 */
  button {
    background-color: #e78420; /* 버튼 배경색 */
    color: white; /* 버튼 텍스트 색상 */
    border: none; /* 테두리 제거 */
    border-radius: 4px; /* 둥근 모서리 */
    padding: 10px 15px; /* 내쪽 여백 */
    margin : 10px;
    cursor: pointer; /* 커서 포인터로 변경 */
    transition: background-color 0.3s; /* 배경색 변화 애니메이션 */
  }
  
  button:hover {
    background-color: #d2761a; /* 호버 시 배경색 변화 */
  }
  
  /* 입력 필드 스타일 */
  input[type="text"],
  input[type="number"] {
    width: 200px; /* 너비를 200px로 설정 */
    padding: 10px 15px; /* 내쪽 여백 */
    margin : 8px; /* 위쪽 여백 */
    margin-left: 106px;
    border: 1px solid #ccc; /* 테두리 */
    border-radius: 4px; /* 둥근 모서리 */
  }
  
  /* 체크박스 스타일 */
  input[type="checkbox"] {
    margin-right: 10px; /* 체크박스 오른쪽 여백 */
  }
  